var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"ns-evalute",staticStyle:{"border-radius":"13px","box-shadow":"rgb(0 0 0 / 3%) 0px 4px 12px"}},[_vm._l((_vm.goodsList),function(item,index){return _c('div',{key:index,staticClass:"ns-eva-li"},[_c('div',{staticClass:"ns-eva-good"},[_c('el-image',{attrs:{"fit":"scale-down","src":_vm.$img(item.sku_image, { size: 'mid' })},on:{"error":function($event){return _vm.imageError(index)},"click":function($event){return _vm.toGoodsDetail(item.sku_id)}}}),_c('p',{staticClass:"ns-eva-good-name",attrs:{"title":item.sku_name},on:{"click":function($event){return _vm.toGoodsDetail(item.sku_id)}}},[_vm._v(_vm._s(item.sku_name))]),_c('p',[_vm._v("฿"+_vm._s(item.price))])],1),_c('div',{staticClass:"ns-eva-form"},[(!_vm.isEvaluate)?_c('div',{staticClass:"block"},[_c('span',{staticClass:"demonstration"},[_vm._v("คะแนนดาว：")]),_c('el-rate',{on:{"change":function($event){return _vm.setStar(index)}},model:{value:(_vm.goodsEvalList[index].scores),callback:function ($$v) {_vm.$set(_vm.goodsEvalList[index], "scores", $$v)},expression:"goodsEvalList[index].scores"}}),_c('div',{staticClass:"level"},[_c('i',{staticClass:"iconfont",class:_vm.goodsEvalList[index].explain_type == '1'
									? 'iconhaoping1 ns-text-color'
									: _vm.goodsEvalList[index].explain_type == '2'
									? 'iconzhongchaping ns-text-color'
									: _vm.goodsEvalList[index].explain_type == '3'
									? 'iconzhongchaping'
									: ''}),_c('span',[_vm._v(" "+_vm._s(_vm.goodsEvalList[index].explain_type == '1' ? 'ยอดเยียม' : _vm.goodsEvalList[index].explain_type == '2' ? 'ปานกลาง' : _vm.goodsEvalList[index].explain_type == '3' ? 'ไม่ค่อยดี' : '')+" ")])])],1):_vm._e(),_c('div',{staticClass:"ns-textarea"},[(!_vm.isEvaluate)?_c('el-input',{attrs:{"type":"textarea","rows":5,"placeholder":"กรุณากรอกความคิดเห็นของคุณที่นี่","maxlength":"200","show-word-limit":""},model:{value:(_vm.goodsEvalList[index].content),callback:function ($$v) {_vm.$set(_vm.goodsEvalList[index], "content", $$v)},expression:"goodsEvalList[index].content"}}):_c('el-input',{attrs:{"type":"textarea","rows":5,"placeholder":"โปรดป้อนรีวิวติดตามผลของคุณที่นี่","maxlength":"200","show-word-limit":""},model:{value:(_vm.goodsEvalList[index].again_content),callback:function ($$v) {_vm.$set(_vm.goodsEvalList[index], "again_content", $$v)},expression:"goodsEvalList[index].again_content"}})],1),_c('el-upload',{ref:"upload",refInFor:true,class:{ ishide: _vm.hide[index] },attrs:{"action":_vm.uploadActionUrl,"data":_vm.uploadData,"list-type":"picture-card","on-success":(file, fileList) => {
							return _vm.handleSuccess(file, fileList, index);
						},"on-preview":_vm.handlePictureCardPreview,"on-remove":(file, fileList) => {
							return _vm.handleRemove(file, fileList, index);
						},"on-exceed":_vm.handleExceed}},[_c('i',{staticClass:"el-icon-plus"})]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})]),_c('span',[_vm._v("สูงสุดได้ "+_vm._s(_vm.imgList[index].length ? 6 - _vm.imgList[index].length : 6)+" รูป")])],1)])}),_c('div',{staticClass:"save-btn-wrap"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("ส่ง")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }